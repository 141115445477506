<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <Container>
    <div class="WrapperStart">
      <EtLogo
        :src="webEtLogo"
        :mobile="isMobile"
        @click="
          handleCheckRoleDisplayMenu(userRole, ['brandAdmin', 'brandGeneral'])
            ? redirectTo('/welcome')
            : ''
        "
      />
      <MobileEtLogo
        :mobile="isMobile"
        @click="
          handleCheckRoleDisplayMenu(userRole, ['brandAdmin', 'brandGeneral'])
            ? redirectTo('/welcome')
            : ''
        "
      >
        <span class="icon-Experience-This-Logo-1"></span>
      </MobileEtLogo>
    </div>
    <div class="WrapperCenter">
      <MenuContainer v-if="!isMobile" :grid="handleDisplayGrid(userRole)">
        <PrimaryTextSmall
          v-if="
            handleCheckRoleDisplayMenu(userRole, ['brandAdmin', 'brandGeneral'])
          "
          class="no-margin-bottom GetStart"
          @click="redirectTo('/welcome')"
        >
          Get started
        </PrimaryTextSmall>
        <PrimaryTextSmall
          v-if="handleCheckRoleDisplayMenu(userRole, ['venueAdmin'])"
          class="no-margin-bottom GetStart"
          @click="redirectTo('/marketplace')"
        >
          Book
        </PrimaryTextSmall>
        <MenuItem
          v-if="handleCheckRoleDisplayMenu(userRole, menu.book.role)"
          :item="menu.book"
          isShowArrow
          name="Book"
        />
        <MenuItem
          v-if="handleCheckRoleDisplayMenu(userRole, menu.manage.role)"
          :item="menu.manage"
          name="Manage"
          isShowArrow
        />
        <MenuItem
          v-if="handleCheckRoleDisplayMenu(userRole, menu.measure.role)"
          :item="menu.measure"
          name="Measure"
          isShowArrow
        />
      </MenuContainer>
    </div>
    <div class="WrapperEnd">
      <RightMenuContainer>
        <!-- <RoundWrapper :hoverColor="true">
        <BellLogo :src="bellLogo" />
      </RoundWrapper> -->
        <el-tooltip
          class="box-item"
          effect="dark"
          :disabled="menu.userInfo.active"
          :content="userData.fullname || userData.email"
          placement="bottom"
        >
          <div ref="menuRef">
            <RoundWrapperName
              :hoverColor="true"
              :mobile="isMobile"
              @click="Open(menu.userInfo)"
            >
              <NameLogo>
                <span>{{ shotName }}</span>
                <Option
                  v-show="menu.userInfo.active"
                  :options="menu.userInfo.options"
                  align="right"
                  :mt="40"
                  @handleCloseMenu="CloseMenu(menu.userInfo)"
                />
              </NameLogo>
            </RoundWrapperName>
          </div>
        </el-tooltip>
        <div ref="menuRefMobile">
          <RoundWrapperBurger
            :hoverColor="true"
            :mobile="isMobile"
            @click="Open(menu)"
          >
            <span class="icon-menu-1" />
            <Option
              v-show="menu.active"
              :mt="50"
              :width="375"
              isMobileMode
              :isActive="menu.active"
              :options="[
                menu.getStart,
                menu.marketPlace,
                menu.book,
                menu.manage,
                menu.measure,
                menu.userInfo,
              ]"
              align="right"
              @handleCloseMenu="CloseMenu(menu)"
            />
          </RoundWrapperBurger>
        </div>
      </RightMenuContainer>
    </div>
  </Container>
</template>

<script>
import * as styles from './styles'
import * as texts from '@/components/ui/styled/text/index'
import { isMobile } from '@/helpers/checkIsMobileDevice'
import MenuItem from './menu_item/menuItem.vue'
import Option from './option/option.vue'
import { mapGetters } from 'vuex'
import signOut from '@/graphql/account/signOut.graphql'
import CONSTANTS from '@/config/url'

export default {
  name: 'NavV2',
  components: {
    ...styles,
    ...texts,
    MenuItem,
    Option,
  },
  data() {
    return {
      webEtLogo:
        'https://s3.eu-west-2.amazonaws.com/static.uat.experiencethis.co/logos/main_nav/Logo+-+dark+(5).png',
      isMobile: isMobile(),
      menu: {
        active: false,
        getStart: {
          name: '',
          options: [
            {
              icon: 'icon-target-goal',
              name: 'Get Started',
              path: '/welcome',
              role: ['brandAdmin', 'brandGeneral'],
            },
          ],
          role: ['brandAdmin', 'brandGeneral'],
        },
        marketPlace: {
          name: '',
          options: [
            {
              icon: 'icon-Shop',
              name: 'Book',
              path: '/marketplace',
              role: ['venueAdmin'],
            },
          ],
          role: ['venueAdmin'],
        },
        book: {
          active: false,
          name: 'Book',
          role: ['brandAdmin', 'brandGeneral'],
          options: [
            {
              icon: 'icon-restaurant1',
              name: 'By Venue',
              path: '/my-venues',
              notiAmount: '',
              role: ['brandAdmin', 'brandGeneral'],
            },
            {
              icon: 'icon-Certificate',
              name: 'By Campaign',
              path: '/campaign/dashboard',
              notiAmount: '',
              role: ['brandAdmin', 'brandGeneral'],
            },
          ],
        },
        manage: {
          active: false,
          name: 'Manage',
          role: [
            'brandAdmin',
            'brandGeneral',
            'agency',
            'venueAdmin',
            'venueGeneral',
            'venueGroup',
          ],
          options: [
            {
              icon: 'icon-restaurant1',
              name: 'My Venues',
              path: '/venues/group/profile',
              notiAmount: '',
              role: ['venueGroup'],
            },
            {
              icon: 'icon-Shop',
              name: 'Marketplace',
              path: '/marketplace',
              notiAmount: '',
              isBookFor: true,
              role: ['venueGroup'],
            },
            {
              icon: 'icon-Calendar1',
              name: 'My Bookings',
              path: '/experience/dashboard',
              notiAmount: '',
              isBookFor: true,
              role: ['venueAdmin', 'venueGeneral', 'venueGroup'],
            },
            {
              icon: 'icon-campaign',
              name: 'Campaign and targets',
              path: '/campaign/dashboard',
              notiAmount: '',
              role: ['brandAdmin'],
            },
            {
              icon: 'icon-Calendar1',
              name: 'Booking requests',
              path: '/requests',
              notiAmount: '',
              role: ['brandAdmin', 'brandGeneral'],
            },

            {
              icon: 'icon-hand-package-delivery',
              name: 'Facilitator requests',
              path: '/requests/booking',
              notiAmount: '',
              role: ['brandAdmin'],
            },
            {
              icon: 'icon-Calendar1',
              name: 'Booking requests',
              path: '/requests/booking',
              notiAmount: '',
              role: ['agency'],
            },
            {
              icon: 'icon-campaign',
              name: 'Campaigns',
              path: '/campaign/dashboard',
              notiAmount: '',
              role: ['agency'],
            },
            {
              icon: 'icon-calendar',
              name: 'Calendar',
              path: '/calendar',
              notiAmount: '',
              role: ['brandAdmin', 'brandGeneral', 'agency'],
            },
            {
              icon: 'icon-bullet',
              name: 'Assets',
              path: '/management/asset',
              notiAmount: '',
              role: ['brandAdmin', 'agency'],
            },
            {
              icon: 'icon-Calendar1',
              name: 'Staff',
              path: '/management/staff',
              notiAmount: '',
              role: ['brandAdmin', 'agency'],
            },
            {
              icon: 'icon-wine-glass-bottle',
              name: 'Stock',
              path: '/management/stock',
              notiAmount: '',
              role: ['brandAdmin'],
            },
            {
              icon: 'icon-Money1',
              name: 'Budgets',
              path: '/management/budget',
              notiAmount: '',
              role: ['brandAdmin', 'brandGeneral'],
            },
            {
              icon: 'icon-Group3',
              name: 'Team',
              path: '/management/team',
              notiAmount: '',
              isBookFor: true,
              role: [
                'brandAdmin',
                'brandGeneral',
                'agency',
                'venueAdmin',
                'venueGeneral',
                'venueGroup',
              ],
            },
          ],
        },
        measure: {
          active: false,
          name: 'Measure',
          role: ['brandAdmin', 'brandGeneral', 'agency'],
          options: [
            {
              icon: 'icon-message-review-star',
              name: 'Rate & Review',
              path: '/management/rate-and-review',
              notiAmount: '',
              role: ['brandAdmin', 'brandGeneral', 'agency'],
            },
            {
              icon: 'icon-Business1',
              name: 'Leaderboard',
              path: '/sales-progress',
              notiAmount: '',
              role: ['brandAdmin', 'brandGeneral'],
            },
          ],
        },
        userInfo: {
          active: false,
          name: '',
          role: ['all'],
          options: [
            {
              header:
                this.$store.getters['account/userData'].fullname ??
                this.$store.getters['account/userData'].email,
              icon: 'icon-Log-out1',
              name: 'Logout',
              path: '',
              notiAmount: '',
              action: this.handleLogout,
              role: ['all'],
            },
          ],
        },
      },
    }
  },
  computed: {
    ...mapGetters('account', [
      'userData',
      'userGroup',
      'isBrandAdminRole',
      'isBrandGeneralRole',
      'isAdminRole',
      'getNotificationBadge',
    ]),
    shotName() {
      return this.getFirstAndLastInitials(this.userData)
    },
    userRole() {
      if (this.isBrandAdminRole) {
        return 'brandAdmin'
      }
      if (this.isBrandGeneralRole) {
        return 'brandGeneral'
      }
      if (this.userGroup === 'venue') {
        return this.isAdminRole ? 'venueAdmin' : 'venueGeneral'
      }

      return this.userGroup
    },
  },
  mounted() {
    this.$nextTick(() => {
      document.addEventListener('click', this.handleClickOutside)
    })
    this.showFacilitatorMenu()
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside)
  },
  methods: {
    handleClickOutside(event) {
      const menuElement = this.$refs.menuRef
      if (menuElement && !menuElement.contains(event.target)) {
        this.CloseMenu(this.menu.userInfo)
      }

      const menuRefMobile = this.$refs.menuRefMobile
      if (menuRefMobile && !menuRefMobile.contains(event.target)) {
        this.CloseMenu(this.menu)
      }
    },
    redirectTo(path) {
      this.$router.push(path)
    },
    Open(item) {
      item.active = !item.active
    },
    CloseMenu(item) {
      item.active = false
    },
    getShortNameEmail(email) {
      const username = email.split('@')[0]

      const nameParts = username.split('_')

      let shortName
      if (nameParts.length === 1) {
        shortName = nameParts[0].charAt(0).toUpperCase()
      } else {
        shortName =
          nameParts[0].charAt(0).toUpperCase() +
          nameParts[nameParts.length - 1].charAt(0).toUpperCase()
      }

      return shortName
    },
    getFirstAndLastInitials(userData) {
      if (userData?.fullname) {
        const nameParts = userData.fullname.trim().split(/\s+/)
        const firstInitial = nameParts[0].charAt(0)
        const lastInitial = nameParts[nameParts.length - 1].charAt(0)

        return `${firstInitial}${lastInitial}`
      }
      return this.getShortNameEmail(userData?.email)
    },
    async handleLogout() {
      this.$router.push(CONSTANTS.ACCOUNT.SIGNIN)
      const assetCollapseImport = localStorage.getItem('assetCollapseImport')
      const assetCollapseCreate = localStorage.getItem('assetCollapseCreate')
      const assetCollapseOnboard = localStorage.getItem('assetCollapseOnboard')
      const assetCollapseMain = localStorage.getItem('assetCollapseMain')

      localStorage.clear()

      localStorage.setItem('assetCollapseImport', assetCollapseImport)
      localStorage.setItem('assetCollapseCreate', assetCollapseCreate)
      localStorage.setItem('assetCollapseOnboard', assetCollapseOnboard)
      localStorage.setItem('assetCollapseMain', assetCollapseMain)

      try {
        let result = await this.$apollo.mutate({
          mutation: signOut,
        })
        if (result.data.signOut.success) {
          this.$store.dispatch('basket/removeAllBasket')
          this.$store.dispatch('account/SIGN_OUT')
        }
      } catch (err) {
        this.$store.dispatch('basket/removeAllBasket')
        this.$store.dispatch('account/SIGN_OUT')
      }
    },
    handleCheckRoleDisplayMenu(role, whiteList) {
      if (!role && !whiteList?.length) return false
      if (whiteList.includes('all')) return true

      return whiteList.includes(role)
    },
    handleDisplayGrid(role) {
      if (role === 'brandAdmin' || role === 'brandGeneral') return 4
      if (role === 'venueAdmin') return 2
      if (role === 'agency') return 2
      else return 1
    },
    showFacilitatorMenu() {
      /**
       * @getNotificationBadge
       * showAgencyDash
       * unReadAgencyDash
       * unReadSponsorshipDash
       */
      if (this.userGroup === 'brand' && this.getNotificationBadge) {
        const findMenu = this.menu.manage.options.find(
          (item) => item.name === 'Facilitator requests',
        )
        const roles = this.getNotificationBadge.showAgencyDash
          ? ['brandAdmin', 'brandGeneral']
          : []
        if (findMenu) {
          findMenu.role = roles
        }
      }
    },
  },
}
</script>
