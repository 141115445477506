/* eslint-disable */
import { createRouter, createWebHistory } from 'vue-router'
import {
  isAccessBookFor,
  checkUserState,
} from '../src/helpers/checkPermitRoute'
import { getUserId, setBeforePath } from '../src/helpers/localStorage'
/* VIEWS
   ----- */
const Home = () => import('./views/Home.vue')
const Welcome = () => import('./views/welcome/Welcome.vue')
//RATE AND REVIEW
const FeedbackVenue = () => import('./views/ratereview/FeedbackVenue/index.vue')
const FeedbackThanks = () => import('./views/ratereview/FeedbackThanks.vue')
const FeedbackAgency = () =>
  import('./views/ratereview/FeedbackAgency/index.vue')
// ACCOUNT
const AccountSignUp = () => import('./views/account/signUp.vue')
const AccountSignUpTeam = () => import('./views/account/signUpTeam.vue')
const AccountSignIn = () => import('./views/account/signIn.vue')
const AccountConfirm = () => import('./views/account/confirm.vue')
const forgetPassword = () => import('./views/account/forgetPassword.vue')
const resetPassword = () => import('./views/account/resetPassword.vue')
const changePassword = () => import('./views/account/changePassword/index.vue')
// EXPERIENCE
const ExperienceDashboard = () =>
  import('./views/experience/ExperienceDashboard')
const ExperienceTimeline = () =>
  import('./views/experience/ExperienceTimeline/Index.vue')
const ExperienceBookingDetails = () =>
  import('./views/experience/ExperienceBookingDetails.vue')
const ExperienceBookingPayment = () =>
  import('./views/experience/ExperienceBookingPayment.vue')
const ExperienceBookingResult = () =>
  import('./views/experience/ExperienceBookingResult.vue')
const SubmittedDecline = () => import('./views/experience/SubmittedDecline.vue')
// CAMPAIGN
const AllCampaignMarketplace = () =>
  import('./views/campaign/marketplace/AllCampaignMarketplace.vue')
const ThemeMarketplace = () => import('./views/marketplace/subthemes')
const CampaignProfile = () => import('./views/campaign/CampaignProfile.vue')
const CampaignPreview = () =>
  import('./views/campaign/CampaignPreview/Index.vue')
const CampaignDashboard = () => import('./views/campaign/CampaignDashboard.vue')
const CreateOrEditCampaign = () =>
  import('./views/campaign/CreateOrEditCampaign.vue')
const CampaignOverview = () =>
  import('./views/campaign/create/CampaignOverview/index.vue')
const AssetsStocksVenueRequirements = () =>
  import('./views/campaign/create/AssetsStocksVenueRequirements.vue')
const TargetVenue = () => import('./views/campaign/create/TargetVenue.vue')
const Budget = () => import('./views/campaign/create/Budget.vue')
const Stakeholders = () => import('./views/campaign/create/Stakeholders.vue')
const Completion = () => import('./views/campaign/create/Completion.vue')
// VENUE SIGNUP
const CreateVenueDetails = () =>
  import('./views/venue/create/CreateVenueDetails.vue')
const VenueDetails = () => import('./views/venue/create/VenueDetails.vue')
const VenueAbout = () => import('./views/venue/create/VenueAbout.vue')
const VenueOfferings = () => import('./views/venue/create/VenueOfferings.vue')
//VENUE PROFILE
const AllVenueProfile = () =>
  import('./views/venue/profile/AllVenueProfile.vue')

const GroupVenueProfile = () =>
  import('./views/venue/profile/GroupVenueProfile')

const AgencyProfile = () => import('./views/agency/AgencyProfile.vue')
//LIST VENUE
const ListVenue = () => import('./views/venue/ListVenue.vue')
// const MyVenue = () => import('./views/venue/MyVenue.vue')
const newVenue = () => import('./views/venue/venueAndGroup/index.vue')
// // MANAGEMENT
const CampaignManagement = () => import('./views/management/CampaignManagement')
const AssetInventory = () => import('./views/management/AssetInventory')
const StaffManagement = () => import('./views/management/StaffManagement')
const StockInventory = () => import('./views/management/StockInventory')
const BudgetManagement = () => import('./views/management/BudgetManagement.vue')
const TeamManagement = () => import('./views/management/TeamManagement')
const RateAndReview = () => import('./views/management/rate-and-review')
// // NOTIFICATION
const BookingApproval = () => import('./views/notification/BookingApproval.vue')
const ExperienceActivationStatus = () =>
  import('./views/notification/ExperienceActivationStatus.vue')

// // SPONSORSHIP REQUEST
const SponsorshipRequests = () =>
  import('./views/booking-dashboard/sponsorship/index.vue')
const BookingDetail = () =>
  import('./views/booking-dashboard/sponsorship/request-detail/index.vue')

// //VENUE GROUP CONFIRMATION
const ConfirmPage = () => import('./views/venue/group/ConfirmPage')

// // CALENDAR
const calendarBrand = () => import('./views/calendar/CalendarBrand')

// // BASKET
const basketSummary = () => import('./views/basket/summary')
// const basketDelivery = () => import('./views/basket/delivery')
const basketDelivery = () =>
  import('@/views/basket/delivery/deliveryNew/index.vue')
const basketCheckout = () => import('./views/basket/checkout')

// // SYSTEM REDIRECTION
const BadRequest = () => import('./views/redirection/BadRequest.vue')
const Forbidden = () => import('./views/redirection/Forbidden.vue')
const NotFound = () => import('./views/redirection/NotFound.vue')

// // TODO: for request
const Example = () => import('./views/agency/request/Request.vue')

// // DATA ANALYTICS
const DataAnalytics = () => import('./views/dataanalytics')

// // SALES PROGRESS
const SalesProgress = () => import('./views/sales-progress')

// // MASS BOOK TERRITORY ALLOCATE
const BasketMassBookTeeritoryAllocate = () =>
  import('./views/basket/massbook/territoryAllocate/territoryAllocate.vue')
// BASKET TERRITORY ALLOCATE
const BasketTeeritoryAllocate = () => import('./views/basket/territoryAllocate')

const FacilitatorDashboardV2 = () => import('./views/facilitator-dashboard')
const FacilitatorDetail = () => import('./views/facilitator-dashboard/facilitator-detail')

import store from './store'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        title: 'Home',
        requireAuth: true,
      },
    },
    /******************************************************************************************/
    /* WELCOME */
    /******************************************************************************************/
    {
      path: '/welcome',
      name: 'welcome',
      component: Welcome,
      meta: {
        title: 'Welcome',
        allowedGroup: ['brand:admin', 'brand:general'],
        requireAuth: true,
      },
    },
    /******************************************************************************************/
    /* RATE AND REVIEW */
    /******************************************************************************************/
    // Rate and review by venue
    {
      path: '/review/venue/',
      name: 'review venue',
      component: FeedbackVenue,
      meta: {
        title: 'ET Rate and Review by Venue',
        requireAuth: false,
      },
    },
    // Rate and review brand and agency
    {
      path: '/review/agency/',
      name: 'review page',
      component: FeedbackAgency,
      meta: {
        title: 'ET Rate and Review by brand and agency',
        requireAuth: false,
      },
    },
    // Feedback thanks
    {
      path: '/review/thanks',
      name: 'review thanks',
      component: FeedbackThanks,
      meta: {
        title: 'ET Rate and Review Thanks',
        template: 'no-main-nav',
        requireAuth: true,
      },
    },
    /******************************************************************************************/
    /* ACCOUNT */
    /******************************************************************************************/
    {
      // Account SignUp
      path: '/account/signup',
      name: 'account signup',
      component: AccountSignUp,
      meta: {
        title: 'ET Account Sign-Up',
        template: 'no-main-nav',
      },
    },
    {
      // Account SignUp
      path: '/account/signup/team',
      name: 'account signup team',
      component: AccountSignUpTeam,
      meta: {
        title: 'ET Account Sign-Up-Team',
        template: 'no-main-nav',
      },
    },
    {
      // SignUp General
      path: '/account/signup/general',
      name: 'signup-general',
      component: AccountSignUp,
      meta: {
        title: 'ET Account Sign-Up',
        template: 'no-main-nav',
      },
    },
    {
      // Account SignIn
      path: '/account/signin',
      name: 'account signin',
      component: AccountSignIn,
      meta: {
        title: 'ET Account Sign-In',
        template: 'no-main-nav',
      },
    },
    {
      // Account Confirm
      path: '/account/confirm/:email',
      name: 'account confirm',
      component: AccountConfirm,
      meta: {
        title: 'ET Account Confirm',
        template: 'no-main-nav',
      },
    },
    {
      path: '/account/forgot',
      name: 'account forgot password',
      component: forgetPassword,
      meta: {
        title: 'ET Account Confirm',
        template: 'no-main-nav',
      },
    },
    {
      path: '/account/reset/:email/:code',
      name: 'account reset password',
      component: resetPassword,
      meta: {
        title: 'ET Account Confirm',
        template: 'no-main-nav',
      },
    },
    {
      path: '/account/changepassword/:email',
      name: 'account change password',
      component: changePassword,
      meta: {
        title: 'ET Account Confirm',
        template: 'no-main-nav',
      },
    },

    /******************************************************************************************/
    /* EXPERIENCE */
    /******************************************************************************************/
    {
      // Experience Dashboard
      path: '/experience/dashboard',
      name: 'experience dashboard',
      component: ExperienceDashboard,
      meta: {
        title: 'ET Experience Dashboard',
        requireAuth: true,
        allowedGroup: [
          'brand:admin',
          'brand:general',
          'venue:admin',
          'venue:general',
        ],
      },
    },
    {
      // Experience Preview
      path: '/experience/preview/:bookingId',
      name: 'experience view',
      component: CampaignPreview,
      meta: {
        title: 'ET Experience Preview',
        requireAuth: true,
      },
    },
    {
      // Experience Timeline
      path: '/experience/timeline/:id',
      name: 'experience timeline',
      component: ExperienceTimeline,
      meta: {
        title: 'ET Experience Timeline',
        requireAuth: false,
      },
    },
    // Experience booking
    {
      path: '/experience/book/:id',
      name: 'vunue booking details',
      component: ExperienceBookingDetails,
      meta: {
        title: 'ET Book Experience: Details',
        requireAuth: true,
      },
    },
    {
      path: '/experience/book/:id/payment',
      name: 'vunue booking payment',
      component: ExperienceBookingPayment,
      meta: {
        title: 'ET Book Experience: Payment',
        requireAuth: true,
      },
    },
    {
      path: '/experience/book/:id/result',
      name: 'booking result',
      component: ExperienceBookingResult,
      meta: {
        title: 'ET Booking result',
        requireAuth: true,
      },
    },

    /******************************************************************************************/
    /* CANCEL */
    /******************************************************************************************/
    // Submitted Decline
    {
      path: '/cancel/submitted',
      name: 'Submitted request',
      component: SubmittedDecline,
      meta: {
        title: 'Submitted Request',
        template: 'no-main-nav',
      },
    },

    /******************************************************************************************/
    /* CAMPAIGN */
    /******************************************************************************************/
    // [ VENUE ]
    {
      // Campaign Marketplace
      path: '/marketplace',
      name: 'campaign marketplace',
      component: AllCampaignMarketplace,
      meta: {
        title: 'ET Campaign Marketplace',
        requireAuth: true,
        allowedGroup: ['venue:admin'],
      },
    },
    {
      // Campaign Marketplace
      path: '/marketplace/subtheme/:id',
      name: 'campaign marketplace subtheme',
      component: ThemeMarketplace,
      meta: {
        title: 'ET Campaign Marketplace',
        requireAuth: false,
      },
    },
    {
      // Campaign Profile
      path: '/campaign/profile/:slug/:bookforId?',
      name: 'campaign profile',
      component: CampaignProfile,
      meta: {
        title: 'ET Campaign Profile',
        requireAuth: false,
      },
    },
    {
      // Campaign Profile
      path: '/campaign/public/:slug',
      name: 'campaign profile public',
      component: CampaignProfile,
      meta: {
        title: 'ET Campaign Profile public',
      },
    },
    {
      // Campaign Preview
      path: '/campaign/preview/:slug',
      name: 'campaign view',
      component: CampaignPreview,
      meta: {
        title: 'ET Campaign Preview',
        requireAuth: false,
      },
    },
    /******************************************************************************************/
    // [ BRAND ]
    {
      // Campaign dashboard
      path: '/campaign/dashboard',
      name: 'campaign dashboard',
      component: CampaignDashboard,
      meta: {
        title: 'ET Campaign Dashboard',
        requireAuth: true,
        allowedGroup: [
          'brand:admin',
          'brand:general',
          'agency:admin',
          'agency:general',
        ],
      },
    },

    {
      // Create campaign
      path: '/campaign/create',
      component: CreateOrEditCampaign,
      meta: {
        allowedGroup: ['brand', 'brand:admin'],
        requireAuth: true,
      },
      children: [
        {
          path: 'overview',
          name: 'create campaign: overview',
          component: CampaignOverview,
          meta: {
            title: 'ET Create New Campaign: Overview',

          },
        },
        {
          path: 'requirements',
          name: 'create campaign: requirements',
          component: AssetsStocksVenueRequirements,
          meta: {
            title:
              'ET Create New Campaign: Requirements: Assets, stock & venue',

          },
        },
        {
          path: 'venue',
          name: 'create campaign: venue',
          component: TargetVenue,
          meta: {
            title: 'ET Create New Campaign: Target venues',

          },
        },
        {
          path: 'budgets',
          name: 'create campaign: budgets',
          component: Budget,
          meta: {
            title: 'ET Create New Campaign: Sponsorship & budgets',

          },
        },
        {
          path: 'stakeholders',
          name: 'create campaign: stakeholders',
          component: Stakeholders,
          meta: {
            title: 'ET Create New Campaign: Agencies & stakeholders',

          },
        },
        {
          path: 'complete',
          name: 'create campaign: done',
          component: Completion,
          meta: {
            title: 'ET Create New Campaign: Campaign created!',

          },
        },
      ],
    },

    {
      // Edit campaign
      path: '/campaign/edit',
      component: CreateOrEditCampaign,
      meta: {
        allowedGroup: ['brand', 'brand:admin'],
        requireAuth: true,
      },
      children: [
        {
          path: 'overview',
          name: 'edit campaign: overview',
          component: CampaignOverview,
          meta: {
            title: 'ET Edit Campaign: Overview',

          },
        },
        {
          path: 'requirements',
          name: 'edit campaign: requirements',
          component: AssetsStocksVenueRequirements,
          meta: {
            title: 'ET Edit Campaign: Requirements: Assets, stock & venue',

          },
        },
        {
          path: 'venue',
          name: 'edit campaign: venue',
          component: TargetVenue,
          meta: {
            title: 'ET Edit Campaign: Target venues',

          },
        },
        {
          path: 'budgets',
          name: 'edit campaign: budgets',
          component: Budget,
          meta: {
            title: 'ET Edit Campaign: Sponsorship & budgets',

          },
        },
        {
          path: 'stakeholders',
          name: 'edit campaign: stakeholders',
          component: Stakeholders,
          meta: {
            title: 'ET Edit Campaign: Agencies & stakeholders',

          },
        },
        {
          path: 'complete',
          name: 'edit campaign: done',
          component: Completion,
          meta: {
            title: 'ET Edit Campaign: Campaign created!',

          },
        },
      ],
    },

    /******************************************************************************************/
    /* VENUE SIGNUP */
    /******************************************************************************************/
    {
      //Venue Details (this is just details page for now and may need
      //to be a child element in future)
      path: '/venue/details',
      name: 'venue details',
      component: VenueDetails,
      meta: {
        title: 'ET Add Venue Details',
      },
    },

    {
      // Create Venue
      path: '/venue/create',
      component: CreateVenueDetails,
      meta: {
        requireAuth: true,
        onboarding: true,
      },
      children: [
        {
          path: '/',
          name: 'create venue: details',
          component: VenueDetails,
          meta: {
            title: 'ET Venue Details',
            template: 'no-left-sidebar',
            hideBasker: true,
          },
        },
        {
          path: 'about',
          name: 'create venue: about',
          component: VenueAbout,
          meta: {
            title: 'ET About Your Venue',
            template: 'no-left-sidebar',
            hideBasker: true,
          },
        },
        {
          path: 'offerings',
          name: 'create venue: offerings',
          component: VenueOfferings,
          meta: {
            title: 'ET Venue Offerings',
            template: 'no-left-sidebar',
            hideBasker: true,
          },
        },
      ],
    },

    /******************************************************************************************/
    /* MANAGEMENT */
    /******************************************************************************************/
    {
      path: '/management/campaign',
      name: 'campaign management',
      component: CampaignManagement,
      meta: {
        title: 'ET Campaign Management',
        requireAuth: true,
        allowedGroup: ['brand:admin'],
      },
    },
    {
      path: '/management/asset',
      name: 'asset inventory',
      component: AssetInventory,
      meta: {
        title: 'ET Asset Inventory',
        requireAuth: true,
        allowedGroup: [
          'brand:admin',
          'brand:general',
          'agency:admin',
          'agency:general',
        ],
      },
      // TODO: Add Asset Inventory path with brand & page parameter to allow browser history
    },
    {
      path: '/management/staff',
      name: 'staff management',
      component: StaffManagement,
      meta: {
        title: 'ET Staff Management',
        requireAuth: true,
        allowedGroup: ['brand:admin', 'agency:admin'],
      },
    },
    {
      path: '/management/stock',
      name: 'stock inventory',
      component: StockInventory,
      meta: {
        title: 'ET Stock Inventory',
        requireAuth: true,
        allowedGroup: ['brand:admin'],
      },
    },
    {
      path: '/management/budget',
      name: 'management budget',
      component: BudgetManagement,
      meta: {
        title: 'ET Management Budget',
        requireAuth: true,
        allowedGroup: ['brand:admin', 'brand:general'],
      },
      // TODO: Add Asset Inventory path with brand & page parameter to allow browser history
    },
    {
      path: '/management/team',
      name: 'team management',
      component: TeamManagement,
      meta: {
        title: 'ET Team Management',
        requireAuth: true,
        allowedGroup: [
          'brand:admin',
          'brand:general',
          'venue:admin',
          'venue:general',
          'agency:admin',
        ],
      },
    },
    {
      path: '/management/rate-and-review',
      name: 'rate and review',
      component: RateAndReview,
      meta: {
        title: 'ET Rate & Review',
        requireAuth: true,
        allowedGroup: [
          'brand:admin',
          'brand:general',
          'agency:admin',
          'agency:general',
        ],
      },
    },

    /******************************************************************************************/
    /* NOTIFICATION */
    /******************************************************************************************/
    {
      path: '/parse-email/booking-approval',
      name: 'booking approval',
      component: BookingApproval,
      meta: {
        title: 'ET Notification: Booking Approval',
        template: 'notification',
      },
    },
    {
      path: '/parse-email/exp-activation-status',
      name: 'experience activation status',
      component: ExperienceActivationStatus,
      meta: {
        title: 'ET Notification: Activation Status',
        template: 'notification',
      },
    },
    /* LIST-VENUE */
    /******************************************************************************************/
    {
      path: '/venues',
      name: 'list venue',
      component: ListVenue,
      meta: {
        title: 'List Venue',
        requireAuth: true,
        allowedGroup: ['brand:admin', 'brand:general'],
      },
    },
    {
      path: '/my-venues/:campaignId?',
      name: 'my venue',
      component: newVenue,
      meta: {
        title: 'My Venue',
        requireAuth: true,
        allowedGroup: ['brand:admin', 'brand:general'],
      },
    },
    {
      path: '/my-venues1/:campaignId?',
      name: 'Newvenue',
      component: newVenue,
      meta: {
        title: 'My Venue',
        requireAuth: true,
        allowedGroup: ['brand:admin', 'brand:general'],
      },
    },
    /******************************************************************************************/
    /* REDIRECTION */
    /******************************************************************************************/
    // 400
    {
      path: '/400',
      name: 'bad request',
      component: BadRequest,
      meta: {
        title: 'ET Redirection: Bad Request',
        template: 'notification',
      },
    },
    // 403
    {
      path: '/403',
      name: 'forbidden',
      component: Forbidden,
      meta: {
        title: 'ET Redirection: Forbidden',
        template: 'notification',
      },
    },
    // 404
    {
      // path: '*',
      path: '/:catchAll(.*)',
      name: '404',
      component: NotFound,
      meta: {
        title: 'ET Redirection: Not Found',
        template: 'notification',
      },
    },
    // /******************************************************************************************/
    // /* PROFILE */
    // /******************************************************************************************/
    {
      path: '/venue/profile',
      name: 'venue profile',
      component: AllVenueProfile,
      meta: {
        title: 'ET Venue Profile',
        requireAuth: true,
        allowedGroup: ['venue:admin'],
      },
    },
    {
      path: '/agency/profile',
      name: 'agency profile',
      component: AgencyProfile,
      meta: {
        title: 'ET Agency Profile',
        requireAuth: true,
        allowedGroup: ['agency:admin'],
      },
    },
    {
      path: '/venues/group/profile',
      name: 'Venue Group Profile',
      component: GroupVenueProfile,
      meta: {
        requireAuth: true,
        title: 'ET Venue Group Profile',
        // template: 'venue-group-sidebar'
      },
    },
    /******************************************************************************************/
    /* SPONSORSHIP REQEUSTS */
    /******************************************************************************************/
    {
      path: '/requests',
      name: 'Sponsorship Requests',
      component: SponsorshipRequests,
      meta: {
        title: 'ET Venue Sponsorship Requests',
        requireAuth: true,
        allowedGroup: ['brand:admin', 'brand:general'],
      },
    },
    {
      path: '/requests/booking/:id',
      name: 'Booking Details',
      component: BookingDetail,
      meta: {
        title: 'ET Venue Sponsorship Requests',
        requireAuth: true,
        // allowedGroup: ['brand:admin', 'brand:general'],
      },
    },
    /******************************************************************************************/
    /* BOOKING REQEUSTS */
    /******************************************************************************************/
    {
      path: '/requests/booking-old',
      name: 'Booking Requests',
      component: Example,
      meta: {
        title: 'ET Booking Requests',
        requireAuth: true,
        // template: 'left-sidebar'
      },
    },
    {
      path: '/requests/facilitator-booking/:id',
      name: 'Booking Details Facilitator',
      component: FacilitatorDetail,
      meta: {
        title: 'ET Venue Sponsorship Requests',
        requireAuth: true,
        // allowedGroup: ['brand:admin', 'brand:general'],
      },
    },
    {
      path: '/requests/booking',
      name: 'Booking Requests',
      component: FacilitatorDashboardV2,
      meta: {
        title: 'ET Booking Requests',
        requireAuth: true,
      },
    },
    /******************************************************************************************/
    /* Calendar */
    /******************************************************************************************/
    {
      path: '/calendar',
      name: 'calendar',
      component: calendarBrand,
      meta: {
        title: 'ET Calendar',
        requireAuth: true,
        allowedGroup: [
          'brand:admin',
          'brand:general',
          'agency:admin',
          'agency:general',
        ],
      },
    },

    /******************************************************************************************/
    /* BASKET */
    /******************************************************************************************/
    {
      path: '/basket',
      name: 'summary',
      component: basketSummary,
      meta: {
        title: 'ET Basket',
        requireAuth: true,
      },
    },
    {
      path: '/basket/delivery',
      name: 'delivery',
      component: basketDelivery,
      meta: {
        title: 'ET Delivery',
        requireAuth: true,
      },
    },
    // {
    //   path: '/basket/delivery1',
    //   name: 'delivery1',
    //   component: basketDelivery1,
    //   meta: {
    //     title: 'ET Delivery
    //     requireAuth: true
    //   }
    // },
    {
      path: '/basket/checkout',
      name: 'checkout',
      component: basketCheckout,
      meta: {
        title: 'ET Checkout',
        requireAuth: true,
      },
    },
    // /******************************************************************************************/
    // /* Venue group confirmation page */
    // /******************************************************************************************/
    {
      path: '/basket/thankyou',
      name: 'ConfirmPage',
      component: ConfirmPage,
      meta: {
        title: 'Confirm Page',
        requireAuth: true,
      },
    },
    /******************************************************************************************/
    /* DATA ANALYTICS */
    /******************************************************************************************/
    {
      path: '/data-analytics/:type/:slug',
      name: 'DataAnalytics',
      component: DataAnalytics,
      meta: {
        title: 'Data Analytics',
        requireAuth: true,
        allowedGroup: ['brand:admin', 'brand:general'],
      },
    },
    /******************************************************************************************/
    /* SALES PROGRESS */
    /******************************************************************************************/
    {
      path: '/sales-progress',
      name: 'SalesProgress',
      component: SalesProgress,
      meta: {
        title: 'Sales Progress',
        requireAuth: true,
        allowedGroup: ['brand:admin', 'brand:general'],
      },
    },
    /******************************************************************************************/
    /* BASKET MASS BOOK TERRITORY ALLOCATE */
    /******************************************************************************************/
    {
      path: '/basket/massbook/territory-allocate/:slug',
      name: 'BasketMassBookTeeritoryAllocate',
      component: BasketMassBookTeeritoryAllocate,
      meta: {
        title: ' Basket MassBook Teeritory Allocate',
        /* BASKET TERRITORY ALLOCATE */
        /******************************************************************************************/
      }
    },
    {
      path: '/basket/territory-allocate/:slug',
      name: 'BasketTerritoryAllocate',
      component: BasketTeeritoryAllocate,
      meta: {
        title: ' Progress',
        requireAuth: true,
      },
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

// NAVIGATION GUARD

// Restriction by sign-in
router.beforeEach((to, from, next) => {
  if (to.matched.some((route) => route.meta.requireAuth)) {
    const userId = getUserId()

    // In case of ?no_redirect=true,
    // then no need to go to /account/signin even user not logged in.
    if (to.query.no_redirect) {
      next()
      return
    }

    if (!userId && !to.query.token) {
      /* set before path */
      if (to.fullPath.search(/management\/asset\?bookingId/gm) > 0) {
        setBeforePath(to.fullPath)
      } else if (to.fullPath.search(/requests/) > 0) {
        setBeforePath(to.fullPath)
      }
      next({ path: '/account/signin' })
    } else next()
  } else next()
})

// Check onboarding
router.beforeEach((to, from, next) => {
  if (to.matched.some((route) => route.meta.requireAuth)) {
    const userId = getUserId()
    const userGroup = store.getters['account/userGroup']

    if (
      userId &&
      !to.matched.some((route) => route.meta.onboarding) &&
      userGroup === 'venue'
    ) {
      const isOnboarding = store.getters['account/isOnboarding']
      if (!isOnboarding) {
        next({ path: '/venue/create' })
      }
    }
    next()
  }
  next()
})

// permission bookfor venue group & brand
// book for leave the another page
/* eslint-disable-next-line */
router.beforeEach((to, from, next) => {
  const role = store.getters['account/userGroup']
  const isBrand = role === 'brand'
  const isBookfor = store.state.account && store.state.account.bookFor
  const hasBasket = store.state.account && store.state.basket.items.length
  const accessPath = isAccessBookFor(to, role)

  if (isBookfor && !accessPath) {
    store.dispatch('account/REMOVE_BOOK_FOR')
    store.dispatch('basket/removeAllBasket')
  }
  if (hasBasket && !accessPath && isBrand) {
    store.dispatch('basket/removeAllBasket')
  }
  next()
})

//check review
router.beforeEach((to, from, next) => {
  const venueWaitingReview = store.state.account.venueWaitingReview
  const userId = getUserId()
  if (venueWaitingReview.length > 0 && userId) {
    store.dispatch('account/CLEAR_REVIEW')
    window.location.href = venueWaitingReview[0]
  } else next()
})

// Restriction by user group
router.beforeEach((to, from, next) => {
  let allowedGroup = to.meta.allowedGroup
  const userGroup = store.getters['account/getUserPermission']

  if (allowedGroup && allowedGroup.indexOf(userGroup) == -1) {
    if (store.state.account && store.state.account.bookFor) {
      next()
    } else {
      console.log('go to signin')
      next({ path: '/account/signin' })
      // next({ path: '/403' })
    }
  } else next()
})

// Check query url userType
router.beforeEach((to, from, next) => {
  const userType = to.query.userType
  localStorage.setItem('userType', userType)
  next()
})

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})

router.afterEach((to, from) => {
  document.title = to.meta.title || 'ExperienceThis'
  // clear state user
  checkUserState(to, from, store)
})

export default router
